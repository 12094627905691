import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./header.scss";

function Header() {
  return (
<>
<Navbar collapseOnSelect expand="lg" className='header px-2'>
        <Navbar.Brand href="#landing"><img src="../images/logo.png" alt="logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link href="#event" eventKey="1">Event</Nav.Link>
            <Nav.Link href="#story" eventKey="2">Our Story</Nav.Link>
            <Nav.Link href="#location" eventKey="3">Location</Nav.Link>
            <Nav.Link href="#rsvp" eventKey="4">RSVP</Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>

    
    {/* // <div classNameNameName="d-flex justify-content-between header px-3 align-items-center pt-2">
    //   <a href="#landing"><img src="../images/logo.png" alt="logo"/></a>

    //   <ul>
    //       <li><a href="#story">Our Story</a></li>
    //       <li><a href="#rsvp">RSVP</a></li>
    //   </ul>
    // </div> */}
</>
  );
}

export default Header;