import './App.scss';
import Header from "./header"
import LandingPg from './landingPg'
import Event from './event'
import ConfirmPresense from './confirmPresence';
import Location from './location';
import Story from './story';

function App() {
  return (
    <div className="App">
      <Header />
      <LandingPg />
      <Event />
      <Story />
      <ConfirmPresense />
      <Location />
    </div>
  );
}

export default App;
