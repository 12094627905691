import "./landingPg.scss"

export default function landingPg() {

    return (
        <div className="landing-page" id="landing">
            <div className="banner">
                <p>Jayaprakash</p>
                <p>&</p>
                <p>Nandhini</p>
            </div>

            <div className="nav-wrapper d-flex justify-content-center flex-column">
                <div className="d-flex justify-content-center">
                    <a href="#event">Event</a>
                    <a href="#story">Our Story</a>
                    <a href="#location">Location</a>
                </div>
                <div className="underline">
                    <span></span>
                    <img src="../images/small-flower.webp" alt="small-flower" />
                    <span></span>
                </div>
            </div>
            <div className="landing-flower right">
                <img src="../images/flower-right.webp" className="w-100" />
            </div>
            <div className="landing-flower left">
                <img src="../images/flower-left.webp" className="w-100" />

            </div>



        </div>
    )
}

