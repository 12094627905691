import * as firebase from "firebase/app";
import "firebase/database";

let config = {
  apiKey: "AIzaSyAmsecSJA-jbaVOqDAwp6AXBTNKLrcjdmk",
  authDomain: "jpweds-2652d.firebaseapp.com",
  projectId: "jpweds-2652d",
  storageBucket: "jpweds-2652d.appspot.com",
  messagingSenderId: "527969351645",
  appId: "1:527969351645:web:82fba1a9bab4d11b3ea834",
  databaseURL:"https://jpweds-2652d-default-rtdb.firebaseio.com/",
  measurementId: "G-21MWHTSR2N"
};

firebase.initializeApp(config);

export default firebase.database();