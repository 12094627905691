import "./story.scss"

export default function Story() {

    return (

        <div className="mb-5 pt-5 story-wrapper" id="story" >
            <div className='d-flex mb-4'>
                <img src="../images/eventflower.png" alt="flower" className='mx-auto heading-flower' />
            </div>
            <h4 className='text-center sub-head-title'>Our Story</h4>

            <div className="col-sm-12 col-md-10 col-lg-8">
                <div className="d-flex align-items-center">
                    <div className="story-title col-6 "><span>Then</span></div>
                    <div className="story-content then col-6">
                        <div className="names overflow-hidden mb-4">
                            <p >
                                <span>J</span>
                                <span className="strikediag">A</span>
                                <span>Y</span>
                                <span>A</span>
                                <span>P</span>
                                <span>R</span>
                                <span>A</span>
                                <span>K</span>
                                <span>A</span>
                                <span>S</span>
                                <span className="strikediag">H</span>
                            </p>
                            <p><img src="../images/heart.png" alt="heart"/></p>
                            <p >
                                <span>N</span>
                                <span className="strikediag">A</span>
                                <span>N</span>
                                <span>D</span>
                                <span className="strikediag">H</span>
                                <span>I</span>
                                <span>N</span>
                                <span>I</span>
                            </p>
                        </div>

                        <p className="flames">
                            <span className="strikediag">F</span>
                            <span className="strikediag">L</span>
                            <span className="strikediag">A</span>
                            <span className="highlighted">M</span>
                            <span className="strikediag">E</span>
                            <span className="strikediag">S</span>

                        </p>

                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="story-content now  col-6">
                    <p className="text-center"><span className="primary-color">We</span> <span className="strike">Our Horoscopes</span> Matched </p>
                    <p className="text-center">&</p>
                    <p className="text-center"> <span className="primary-color">She</span> <span className="strike">The ஐயர்</span> Said Yes !</p>
                    </div>
                    <div className="story-title col-6"><span>Now</span></div>
                </div>
            </div>
        </div>
    )
}