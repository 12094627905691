import Timer from './timer'
import "./event.scss"


export default function Event() {

    return (
        <div className='mb-5 event-wrapper' id="event">
            <div className='d-flex mb-4'>
                <img src="../images/eventflower.png" alt="flower" className='mx-auto heading-flower' />
            </div>
            <h4 className='text-center sub-head-title'>Our Event</h4>
            <div className="d-flex event justify-content-center align-items-center text-center mb-5">
                <div className="first-event">
                    <p >Marriage</p>
                    <span>Aug 31th <span >|</span> 9 AM</span>
                </div>
                <div >
                    <p >Reception</p>
                    <span>Sep 1st <span >|</span> 6 PM</span>
                </div>

            </div>

            <div className='event-right-flower'>
                <img src="../images/event-right-flower.png" className='w-100' />

            </div>


            <Timer />

            <div className="d-flex justify-content-center live-video-wrapper">

                <div className="live-video col-sm-12 col-md-8 col-lg-6 overflow-hidden">
                    <div className="text-center px-3">
                        <p className='pt-3'>Things are beautiful when seen in person,
                            We really love your presence,
                            Just incase if you are not able to make it,</p>

                        <p className='mb-3'>
                            <strong>Live streaming</strong> starts on the Big day.<strong> Click image to view it.</strong></p>
                        <div className="d-flex flex-column justify-content-center align-items-center image-wrapper">
                            {/* <img src="../images/comming-soon.jpeg" className='live-demo-img' alt="live-video icon" /> */}

                            <a href='https://maaevents9.co.in/jayaprakashandnandhini' target="_blank"><img src="../images/wedding-img.png" className='live-demo-img' alt="live-video icon" /></a>
                            <a className='mt-5' href='https://maaevents9.co.in/jayaprakashwithnandhini' target="_blank"><img src="../images/reception-img.png" className='live-demo-img' alt="live-video icon" /></a>

                        </div>

                    </div>
                    <div className='event-right-image'>
                        <img src="../images/event-flower-left.png" alt="image" className='w-100' />
                    </div>
                </div>

            </div>


        </div>


    )
}