import React, { useState } from 'react';
import "./location.scss"

function Location() {
    const [isMarraigeMap, setMarriageMap] = useState(true);
    const [isRecpMap, setRecpMap] = useState(false);

    const showMarriageMap = () => {
        setMarriageMap(true);
        setRecpMap(false);
    }
    const showRecpMap = () => {
        setRecpMap(true);
        setMarriageMap(false);
    }

    return (

        <div id="location" className="">
            <div className="col-12 location-wrapper">

                <div className="col-sm-12 col-md-12 col-lg-6 location-map">

                    {isMarraigeMap && <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.93745792941527!2d80.26270483691667!3d13.035714554029385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267d254b2e533%3A0xcb27d974b0bcdb89!2sKapaleeshwarar%20Temple!5e0!3m2!1sen!2sca!4v1659656897562!5m2!1sen!2sca" width="100%" height="490" className="border-0" allowFullScreen={true} loading="lazy"></iframe>}
                    {isRecpMap && <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.504439046154!2d80.28947531533372!3d13.130549790751715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52661f812837cd%3A0x44e08d24ab24de18!2sVPMA&#39;s%20Captain%20Mahal!5e0!3m2!1sen!2sca!4v1659657020718!5m2!1sen!2sca" width="100%" height="490" className="border-0" allowFullScreen={true} loading="lazy"></iframe>}

                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 location-content d-flex flex-column">
                    <h6 className='pt-5'><span>Location</span></h6>
                    <h4 className='text-center sub-head-title mb-5 pt-4'>When & Where</h4>
                    {/* <h3 className="text-center mb-5 pt-4"></h3> */}
                    <div className="d-flex address-content">
                        <div className="text-center">
                            <div className='pb-3'>
                                <img src="../images/location-marraige.png" alt="marriage icon" />
                            </div>
                            <h5 >The Marriage</h5>
                            <p className="mb-1">Wednesday, Aug-31</p>
                            <p>09:00 AM - 10.30 AM</p>
                            <p className="mb-1">Kapaleeshwarar Temple</p>
                            <p>Mylapore, Chennai</p>

                            <div className="text-center mx-0 ">
                                <button onClick={showMarriageMap} className={isMarraigeMap ? 'active btn' : 'btn'} >see map</button>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className='pb-3'>
                                <img src="../images/location-reception.png" alt="reception icon" />
                            </div>
                            <h5>The Reception</h5>
                            <p className="mb-1">Thursday, Sep-01</p>
                            <p>06:00 PM - 09.00 PM</p>
                            <p className="mb-1">VPMA's Captain Mahal</p>
                            <p>Tondiarpet, Chennai</p>
                            <div className="text-center mx-0 ">
                                <button onClick={showRecpMap} className={isRecpMap ? 'active btn' : 'btn'} >see map</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Location;